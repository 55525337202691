module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"obuchi-jibika","short_name":"Obuchi","start_url":"/","background_color":"#241714","theme_color":"#241714","display":"minimal-ui","icon":"src/images/logo-nega.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"7f5e183cc454ba5afcfb51b6e0e74014"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"runtimeCaching":[{"urlPattern":"https://script.google.com/macros/s/AKfycbzLCRfiUJOBHRqwqo8htjFdjSLiuyvjMjmyeqqQ6lvi4OtVMtc/exec","handler":"staleWhileRevalidate"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-130059476-1"},
    }]
